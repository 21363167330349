<template>
  <div>
    <form @submit.prevent="save(true)" @keydown="form.onKeydown($event)">
      <div class="d-flex justify-content-between flex-lg-row flex-column">
        <div>
          <h2 class="pb-2" v-if="title">{{ title }}</h2>
          <p class="pb-2" v-if="description">{{ description }}</p>
        </div>
      </div>
      <h3 class="card-title" v-if="profile">
        Manage Curriculum Activities :
      </h3>

      <b-row>
        <b-col lg="6">
          <b-row>
            <b-col cols="12" lg="6">
              <div class="form-group">
                <label>Title*</label>
                <Input
                    v-model="form.title"
                    placeholder="Enter Title"
                    style="width: 100%"
                    type="text"
                />
                <has-error
                    :form="form"
                    field="title"
                ></has-error>
              </div>
            </b-col>

            <b-col cols="12" lg="6">
              <div class="form-group">
                <label>Type*</label>
                <Select
                    v-model="form.type"
                    :clearable="true"
                    placeholder="Salary Filter Type"
                >
                  <Option :value="1"> Extra-curricular activities </Option>
                  <Option :value="2"> Co-curricular activities </Option>
                </Select>
                <has-error
                    :form="form"
                    field="type"
                ></has-error>
              </div>
            </b-col>

            <b-col lg="12">
              <b-row>
                <b-col cols="12" lg="6">
                  <div class="form-group">
                    <label>Starting Date (Optional)</label>
                    <DatePicker
                        v-model="starting_date"
                        style="width: 100%"
                        type="date"
                        placeholder="Select Starting date">
                    </DatePicker>
                    <has-error
                        :form="form"
                        field="starting_date"
                    ></has-error>
                  </div>
                </b-col>

                <b-col cols="12" lg="6">
                  <div class="form-group">
                    <label>Ending Date</label>
                    <DatePicker
                        v-model="ending_date"
                        type="date"
                        placeholder="Select Ending date"
                        style="width: 100%"
                        :disabled="form.running">
                    </DatePicker>
                    <has-error
                        :form="form"
                        field="ending_date"
                    ></has-error>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <div class="form-group text-left mb-1">
                <p>
                  <Checkbox
                      label="" border
                      v-model="form.running">
                    &nbsp;Running
                  </Checkbox>
                </p>
                <has-error
                    :form="form"
                    field="running"
                ></has-error>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6" cols="12">
          <label>Job Description</label>
          <Input
              v-model="form.description"
              placeholder="Enter Job Description"
              style="width: 100%"
              type="textarea"
              :rows="4"
          />
          <has-error
              :form="form"
              field="description"
          ></has-error>
        </b-col>
      </b-row>

      <div class="w-100 text-right">
        <b-row class="pt-1">
          <b-col class="d-flex  justify-content-between"
                 cols="4"
                 lg="6"
          >
            <b-button
                variant="primary"
                v-if="hasExperience && !profile"
                @click="nextOnboard(nextName, nextTitle)"
            >
              Next
            </b-button>
            <router-link to="/profile" v-if="profile">
              <b-button
                  variant="primary" >
                Back to profile
              </b-button>
            </router-link>
          </b-col>
          <b-col cols="8" lg="6">
            <b-button
                variant="danger"
                @click="clear()"
            >
              Clear
            </b-button>
            <b-button
                variant="primary"
                class="ml-1"
                type="submit"
                :disabled="form.busy"
            >
              {{ form.id ? 'Update' : 'Add' }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </form>
    <hr class="mt-2">
    <div class="card border"
         v-for="(curricular_activity, index) in curricular_activities.data"
         v-if="curricular_activities.data.length"
         :key="index"
    >
      <div class="card-body experience-card">
        <b-row>
          <b-col cols="12" lg="12"
                 class="d-flex
                 justify-lg-content-between flex-column flex-lg-row"
          >
            <div class="order-1 order-lg-0">
              <h5 class="card-title font-weight-bolder">{{ curricular_activity.title }}</h5>
            </div>
            <div class="order-0 order-lg-1 ml-auto">
              <button
                  class="btn btn-primary btn-sm"
                  @click="edit(curricular_activity.id)"
              >
                <feather-icon icon="EditIcon"/>
              </button>
              <button
                  class="btn btn-danger btn-sm ml-1"
                  @click="showConfirmation(curricular_activity.id)"
              >
                <feather-icon icon="TrashIcon"/>
              </button>
            </div>
          </b-col>

          <b-col cols="12" v-if="curricular_activity.starting_date">
            <h6 class="card-subtitle mb-2 text-secondary">
              ({{ curricular_activity.starting_date }} - {{ curricular_activity.ending_date ? curricular_activity.ending_date : "Running"}})</h6>
          </b-col>
          <b-col cols="12">
            <h6 class="card-subtitle mb-2" :class="curricular_activity.type == 'Extra-curricular activities' ? 'badge badge-primary' : 'badge badge-success'">{{ curricular_activity.type }}</h6>
          </b-col>
          <b-col>
            <p class="card-text">{{ curricular_activity.description }}</p>
          </b-col>
        </b-row>
      </div>
    </div>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import {HasError} from "vform/src/components/bootstrap5";

export default {
  props: ["title", "nextOnboard", "nextTitle", "nextName", "description", "profile"],
  data() {
    return {
      form: new Form({
        id: null,
        title: null,
        description: null,
        starting_date: null,
        ending_date: null,
        type: null,
        running: false,
        status: true
      }),
      starting_date: null,
      ending_date: null,
      hasExperience: true,
      showModal: false,
      curricular_activities: {},
    };
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
  },
  mounted() {
    this.showCurriculum()
  },
  methods: {
    clear() {
      this.form.id = null;
      this.form.title = null;
      this.form.description = null;
      this.form.starting_date = null;
      this.starting_date = null;
      this.ending_date = null;
      this.form.ending_date = null;
      this.form.type = null;
      this.form.running = false;
      this.form.status = true;
    },

    formatDate(date) {
      if (date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      return null;
    },
    save(status) {
      if(!this.starting_date && (this.ending_date || this.form.running)){
        return this.e('Please enter starting date.');
      }
      if(this.starting_date && !this.ending_date && !this.form.running){
        return this.e('Please enter ending date.');
      }
      this.form.status = status;
      this.form.starting_date = this.formatDate(this.starting_date);
      this.form.ending_date = this.form.running ? null : this.formatDate(this.ending_date);
      if (!this.form.id) {
        this.form.post('/candidate/curricular-activities')
            .then(response => {
              this.s(response.data.message);
              this.clear();
              this.showCurriculum();
              this.$store.dispatch("onboardData");
            })
            .catch((e) => {
              this.e(e.data.error);
            });
      } else {
        this.form.put('/candidate/curricular-activities/' + this.form.id)
            .then(response => {
              this.s(response.data.message);
              this.clear();
              this.showCurriculum();
            })
            .catch((e) => {
              this.e(e.data.error);
            });
      }
    },
    edit(id) {
      axios
          .get("/candidate/curricular-activities/" + id + "/edit")
          .then((res) => {
            let curricular_activity = res.data.curricular_activity;
            this.form.id = curricular_activity.id;
            this.form.title = curricular_activity.title;
            this.form.description = curricular_activity.description;
            this.starting_date = curricular_activity.starting_date;
            this.ending_date = curricular_activity.ending_date;
            this.form.ending_date = curricular_activity.ending_date;
            this.form.type = curricular_activity.type;
            this.form.running = curricular_activity.running == 1 ? true : false;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    destroy(id) {
      axios
          .delete("/candidate/curricular-activities/" + id)
          .then((response) => {
            this.s(response.data.message);
            this.showCurriculum();
            this.$store.dispatch("onboardData");
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    showCurriculum() {
      axios
          .get("/candidate/curricular-activities")
          .then((res) => {
            this.curricular_activities = res.data;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    showConfirmation(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You want to Delete!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, do it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.destroy(id);
        }
      })
    },
  }
};
</script>

<style scoped>
.onboard-form .card-body {
  display: block !important;
}

.experience-card {
  background-color: #fafafa;
}
</style>